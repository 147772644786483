<template lang="pug">
div
  .sidebyside
    img(src="../assets/chicken4-res.png")
    .interest
      h1 Are you interested in buying dried mealworms?
      .button(v-if="!capturedEmail")
        form(id="google-form" name="submit-to-google-sheet")
          input( name='email' v-model="email" placeholder="Email").input-format.emailInput
          button(type="submit" ).input-format.submitButton Sign Up
        //- button(@click="yesEmail") Yes
        //- button No
      .button(v-else)
        a Thank you, we'll contact you when we're ready!
</template>

<script>
const scriptURL = 'https://script.google.com/macros/s/AKfycbxnLXv103RtE9xV-5F06kr8P-V4GpjRUpZhtDO_58Fgk9juBjc/exec'

export default {
  name: 'HelloWorld',
  data() {
    return {capturedEmail:0}
  },
  computed: {
    classes() {
      return {
        'email-confirmation': this.capturedEmail,
      };
    },

  },
  methods: {
    submitEmail() {
      console.log(this.email)
      if (this.email) {
        this.capturedEmail = 1;
      }
    },
    yesEmail() {
      console.log("yooo")
      this.capturedEmail = 1;

    }
  },
  mounted() {
    var form = document.getElementById('google-form');
    console.log(form)

    form.addEventListener('submit', e => {
      var what = new FormData(form);
      console.log(what)
      e.preventDefault()
      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
        .then((response) => {
          console.log('Success!', response)
          this.capturedEmail = 1;
        })
        // .then(response => console.log('Success!', response))
        .catch(error => console.error('Error!', error.message))
    })
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less'>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.interest {
  margin-bottom: 100px;
  .input-format {
    font-size: 20px;
    margin-right: 5px;
  }
  .emailInput {
    width: 65%;
    font-weight: normal;
  }
  .submitButton {
    width: 25%;
    min-width: 40px;
  }
  input {
    min-height: 36px;
  }
  button {
    min-width: 100px;
    min-height: 40px;
    margin: 2px;
  }

}


.sidebyside {
  img {
    max-width: 250px;
  }
  h1 {
    max-width: 400px;
  }
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
